.Card {
  background-color: var(--grey-background-1);
  border: 1px solid var(--grey-border-2);
  border-radius: 4px;
  height: calc(100% - 2px);
}

.Card > .CardRow {
  border-bottom: 1px solid var(--grey-border-2);
}

.Card > .CardRow:last-child {
  border-bottom: none;
}

.Card > .CardRow-normal:first-child, .Card > .CardRow-normal:last-child {
  height: 39px;
}

.Card > .CardRow-large:first-child, .Card > .CardRow-large:last-child {
  height: 71px;
}
