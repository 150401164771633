.Header {
  width: 100%;
  display: flex;
  fill: var(--grey-dark);
}

.Header-size-small {
  height: 56px;
}

.Header-size-normal {
  height: 64px;
}

.Header-size-large {
  height: 72px;
}

.Header-color-dark {
  background-color: var(--grey-dark);
}

.Header-color-very-dark {
  background-color: var(--black-menu);
}

.Header-color-light {
  background-color: white;
}

.Header-color-purple {
  background-color: var(--purple);
}

.Header-color-blue {
  background-color: var(--blue);
}

.Header-shadow {
  box-shadow: 0px -4px 9px 0px black;
  position: relative;
  z-index: 1;
}

.Header-color-dark .Header-button, .Header-color-purple .Header-button, .Header-color-very-dark .Header-button, .Header-color-blue .Header-button{
  fill: white;
}

.Header-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header-size-small .Header-button {
  width: 56px;
}

.Header-size-normal .Header-button {
  width: 64px;
}

.Header-size-large .Header-button {
  width: 72px;
}
