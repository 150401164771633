.AJO-popover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJO-container {
  background-color: white;
  width: 80%;
  height: 202px;
  display: flex;
  flex-flow: column;
}

.AJO-popover-buttons {
  display: flex;
}

.AJO-popover-head {
  height: 48px;
  padding: 0 16px;
  border-bottom: 1px solid var(--grey-border-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJO-popover-input {
  flex: 1;
  padding: 8px;
}

.AJO-popover-input :first-child {
  flex: 1;
}

.AJO-popover-input :last-child {
  flex: 3;
}

.AJO-popover-input textarea {
  height: calc(100% - 16px);
  resize: none;
}

.AJO-popover-input {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
