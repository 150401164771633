.FinishedWorkItem {
  height: 40px;
  background-color: var(--green);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.FinishedWorkItem-icon {
  flex: 0 0 40px;
  fill: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FinishedWorkItem-fill {
  flex: 1;
}

.FinishedWorkItem:not(:last-child) {
  margin-bottom: 8px;
}
