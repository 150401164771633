.Messages {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: calc(100% - 56px);
  pointer-events: none;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.Message-row {
  background-color: var(--grey-dark);
  height: 44px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.Message-type-error {
  background-color: var(--red);
}
