.DropdownOption:first-child {
  height: 46px;
  border-top: 1px solid var(--grey-border-2);
}

.DropdownOption {
  border-bottom: 1px solid var(--grey-border-2);
  height: 47px;
  display: flex;
  cursor: pointer;
}

.DropdownOption:hover {
  background-image: linear-gradient(#00000010, #00000010);
}

.DropdownOption-label {
  margin-left: 48px;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}

.DropdownOption-check {
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
