.Job-comments {
  border-top: 1px solid var(--grey-border-2);
}

.Job-comment {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-border-2);
  height: 48px;
}

.Job-comment-icon {
  flex: 0 0 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Job-comment-text {
  flex: 1;
}

.Job-comments button {
  height: 48px;
  text-align: left;
}

.Job-info-columns button {
  height: initial;
}

.Job-pictures {
  margin-bottom: 20px;
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
}

.Job-picture-container:nth-child(odd) {
  margin-right: 8px;
}

.Job-picture-container {
  width: calc(50vw - 16px - 4px);
  height: calc(50vw - 16px - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Job-picture-container img {
  max-width: 100%;
  max-height: 100%;
}

.Job-picture-container button {
  width: 100%;
  height: 100%;
}
