.Job-title {
  height: 100%;
  display: flex;
  align-items: center;
}

.Job-tabs {
  display: flex;
  box-shadow: 0 5px 5px 0px #00000010;
  position: relative;
}

.Job-tabs > div {
  flex: 1;
}

.Job-tabs-active {
  background-color: var(--orange-light);
  position: relative;
}

.Job-tabs-active-underline {
  width: 100%;
  height: 4px;
  background-color: var(--orange);
  position: absolute;
  left: 0;
  bottom: 0;
}

.Job-scroll {
  overflow-y: scroll;
  height: calc(var(--fullvh) - 168px);
}

.Job-info-row {
  display: flex;
  height: 47px;
  border-bottom: 1px solid var(--grey-border-2);
}

.Job-info-row-grow {
  display: flex;
  min-height: 47px;
  padding: 8px 0;
  box-sizing: border-box;
}

.Job-info-columns {
  display: flex;
  border-bottom: 1px solid var(--grey-border-2);
}

.Job-info-columns > * {
  flex: 3;
}

.Job-info-columns > :first-child {
  border-right: 1px solid var(--grey-border-2);
}

.Job-info-row-icon {
  flex: 0 0 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Job-info-row-label {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}

.Job-info-row-label-column {
  flex-flow: column;
  align-items: flex-start;
}

.Job-info-row-label-right {
  justify-content: flex-end;
}

.Job-footer-button-container {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 3px 9px black;
}

.Job-footer-button-container > :first-child {
  flex: 1;
}

.Job-footer-button-container > :last-child {
  flex: 2;
}

.Job-footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Job-footer-button > :first-child {
  margin-right: 15px;
}

.Job-a {
  text-decoration: none;
}

.Job-loading {
  position: relative;
  height: calc(var(--fullvh) - 56px);
}
