.Text {
  margin: 0;
}

.Text-color-text {
  color: var(--grey-text);
}

.Text-color-white {
  color: white;
}

.Text-color-light {
  color: var(--grey-border-1);
}

.Text-color-dark {
  color: var(--grey-dark);
}

.Text-color-purple {
  color: var(--purple);
}

.Text-color-purple-alt {
  color: var(--purple-alt);
}

.Text-color-orange {
  color: var(--orange);
}

.Text-color-blue {
  color: var(--blue);
}

.Text-size-normal {
  font-size: 14px;
}

.Text-size-tiny {
  font-size: 11px;
}

.Text-size-extra-tiny {
  font-size: 10px;
}

.Text-size-small {
  font-size: 12px;
}

.Text-size-large {
  font-size: 18px;
}

.Text-weight-normal {
  font-weight: 400;
}

.Text-weight-medium {
  font-weight: 600;
}

.Text-weight-bold {
  font-weight: 700;
}
