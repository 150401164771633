.AJO-header {
  display: flex;
  align-items: center;
  flex: 1;
}

.AJO-header > :first-child {
  flex: 1;
  text-align: center;
}

.AJO-header > :last-child {
  flex: 0 0 112px;
}

.AJO-option {
  height: 47px;
  border-bottom: 1px solid var(--grey-border-2);
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.AJO-search {
  display: flex;
  align-items: center;
  padding: 16px 16px 8px 0;
  border-bottom: 1px solid var(--grey-border-2);
}

.AJO-search-icon {
  width: 56px;
  display: flex;
  justify-content: center;
}

.AJO-scroll {
  height: calc(var(--fullvh) - 56px);
  overflow-y: scroll;
  position: relative;
}
