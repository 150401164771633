.Button {
  border: none;
  height: 56px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  padding: 0;
}

.Button-color-transparent {
  background-color: transparent;
  color: var(--grey-text);
}

.Button-color-orange {
  background-color: var(--orange);
  color: white;
}

.Button-color-red {
  background-color: var(--red);
  color: white;
}

.Button-color-green {
  background-color: var(--green);
  color: white;
}

.Button-color-grey {
  background-color: var(--grey-border-2);
  color: var(--grey-dark-50);
}

.Button-color-dark {
  background-color: var(--grey-dark);
  color: white;
}

.Button-color-purple {
  background-color: var(--purple);
  color: white;
}

.Button-color-purple-medium {
  background-color: var(--purple-medium);
  color: white;
}

.Button-color-blue {
  background-color: var(--blue);
  color: white;
}

.Button-color-blue-medium {
  background-color: var(--blue-medium);
  color: white;
}

.Button-weight-bold {
  font-weight: 700;
}

.Button-weight-normal {
  font-weight: 400;
}

.Button:disabled {
  opacity: 0.5;
}
