.Loader {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loader-fullpage {
    background-color: #00000030;
    z-index: 2;
}

.Loader-circle {
    position: absolute;
    width: 70px;
    height: 70px;
    animation: 8s linear 0s infinite running rotate;
    fill: none;
    stroke: var(--grey-dark);
    stroke-width: 2;
    stroke-dasharray: 50;
}

.Loader-circle-medium {
    width: 60px;
    height: 60px;
    animation: 8s linear 0s infinite reverse running rotate;
    stroke-dashoffset: 50;
}

.Loader-circle-small {
    width: 50px;
    height: 50px;
}

.Loader-size-small .Loader-circle {
    width: 30px;
    height: 30px;
    stroke-width: 3;
}

.Loader-size-small .Loader-circle-medium {
    width: 20px;
    height: 20px;
}

.Loader-size-small .Loader-circle-small {
    display: none;
}

.Loader-color-white .Loader-circle {
    stroke: white;
}

@keyframes rotate {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
