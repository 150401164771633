.AddJob-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddJob-header-button {
  position: absolute;
  width: 100px;
  right: 0;
}

.AddJob-row {
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AddJob-row > p {
  flex: 1;
}

.AddJob-drill {
  background-color: var(--purple);
}

.AddJob-saw {
  background-color: var(--blue);
}

.AddJob-other {
  background-color: var(--grey-dark);
}

.AddJob-row-icon {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
