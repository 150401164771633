.AJS-header {
  display: flex;
  align-items: center;
  flex: 1;
}

.AJS-header > :first-child {
  flex: 0 0 56px;
}

.AJS-header > :nth-child(2) {
  flex: 1;
  text-align: center;
}

.AJS-header > :last-child {
  flex: 0 0 112px;
}

.AJS-scroll {
  overflow-y: scroll;
  height: calc(var(--fullvh) - 56px - 56px);
}

.AJS-inputs {
  margin-bottom: 8px;
}

.AJS-input-row {
  border-bottom: 1px solid var(--grey-border-2);
  display: flex;
}

.AJS-input-row > :first-child {
  border-right: 1px solid var(--grey-border-2);
}

.AJS-input-row > * {
  flex: 1
}

.AJS-input {
  display: flex;
  align-items: center;
}

.AJS-input-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJS-input-fill {
  flex: 0 0 48px;
  display: flex;
  justify-content: center;
}

.AJS-input-field {
  flex: 1;
}

.AJS-buttons {
  display: flex;
  position: relative;
  box-shadow: 0px 3px 9px black;
}

.AJS-button {
  display: flex;
  justify-content: center;
}

.AJS-button-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJS-button-text {
  display: flex;
  align-items: center;
}

.AJS-button-text > p {
  text-align: left;
}

.AJS-extra-row {
  display: flex;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid var(--grey-border-2);
}

.AJS-extra > :first-child .AJS-extra-row {
  height: 46px;
  border-top: 1px solid var(--grey-border-2);
}

.AJS-extra-checkbox {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJS-extra-checkbox-label {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.AJS-extra-checkbox-label label {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}
