.Bill-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--purple);
}

.Bill-header-icon {
  position: absolute;
  left: 22px;
}

.Bill-table-header {
  height: 40px;
  display: flex;
}

.Bill-table-head {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bill-table-header .Bill-table-cell-small {
  flex: 0 0 55px;
  border-right: 1px solid var(--grey-border-2);
}

.Bill-table-header .Bill-table-cell-wide {
  flex: 1 0 69px;
  border-right: 1px solid var(--grey-border-2);
}

.Bill-table-row .Bill-table-cell-small {
  flex: 0 0 56px;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bill-table-row .Bill-table-cell-wide {
  flex: 1 0 69px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple-light);
}

.Bill-table-cell-column {
  flex-flow: column;
}

.Bill-table-cell-fill {
  flex: 0 0 96px;
}

.Bill-table-row {
  display: flex;
  flex-flow: column;
  border-top: 1px solid white;
}

.Bill-table-row-top {
  display: flex;
}

.Bill-table-row-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 96px;
  background-color: var(--purple-light);
}

.Bill-table-row-buttons button {
  height: 48px;
  width: 48px;
}

.Bill-table-row-info {
  display: flex;
  flex: 1 0 210px;
  flex-flow: column;
}

.Bill-table-row-info-values {
  display: flex;
  height: 48px;
}

.Bill-table-row-info-extra {
  background-color: var(--purple-extra-light);
  padding-left: 56px;
  position: relative;
}

.Bill-table-row-info-extra-row {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.Bill-table-done-row {
  display: flex;
  height: 48px;
}

.Bill-table-done-buttons {
  background-color: initial;
  position: relative;
}

.Bill-table-done-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #FFFFFF80;
}

.Bill-table-done-worker {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 8px;
}

.Bill-table-done-amount {
  flex: 0 0 56px;
  display: flex;
}

.Bill-table-done-amount-arrow {
  flex: 1;
  position: relative;
}

.Bill-table-done-amount-number {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}

.Bill-table-done-amount-line {
  position: absolute;
  left: 12px;
  width: 8px;
  height: 24px;
  border: 2px solid var(--purple);
  border-top: none;
  border-right: none;
}

.Bill-table-done-amount-point {
  position: absolute;
  left: 22px;
  top: 15px;
}

.Bill-table-done-amount-extend {
  position: absolute;
  left: 12px;
  bottom: 0;
  width: 0px;
  height: 24px;
  border: 2px solid var(--purple);
  border-top: none;
  border-right: none;
}

.Bill-table-done-amount-initial {
  position: absolute;
  left: 12px;
  width: 8px;
  height: 100%;
  border-left: 2px solid var(--purple);
}

.Bill-container-hidden {
  display: none;
}

.Bill-container-saw .Bill-header {
  background-color: var(--blue);
}

.Bill-container-saw .Bill-table-row .Bill-table-cell-small {
  background-color: var(--blue);
}

.Bill-container-saw .Bill-table-row .Bill-table-cell-wide {
  background-color: var(--blue-light);
}

.Bill-container-saw .Bill-table-row-buttons {
  background-color: var(--blue-light);
}

.Bill-container-saw .Bill-table-done-buttons {
  background-color: initial;
}

.Bill-container-saw .Bill-table-row-info-extra {
  background-color: var(--blue-extra-light);
  padding-left: 56px;
  position: relative;
}

.Bill-container-saw .Bill-table-done-amount-line {
  border: 2px solid var(--blue);
  border-top: none;
  border-right: none;
}
