.Menu {
  background-color: var(--black-menu);
  height: var(--fullvh);
  display: flex;
  flex-flow: column;
}

.Menu-header {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Menu-header-close {
  position: absolute;
  left: 0;
  width: 56px;
  height : 56px;
}

.Menu-content {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}

.Menu-image {
  padding-top: 38px;
}

.Menu-version {
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu-logout {
}

.Menu-language {
  
}
