.Textarea {
  background-color: var(--grey-background-1);
  width: calc(100% - 16px);
  border: 1px solid var(--grey-border-1);
  border-radius: 2px;
  padding: 7px;
  font-size: 14px;
  font-weight: 700;
  color: var(--grey-dark);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
