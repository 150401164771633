.CardRow {
  box-sizing: border-box;
}

.CardRow-size-small {
  height: 32px;
}

.CardRow-size-normal {
  height: 40px;
}

.CardRow-size-large {
  height: 72px;
}

.CardRow-size-grow {
  min-height: 32px;
}
