.Checkbox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--grey-border-1);
  background-color: var(--grey-background-2);
  cursor: pointer;
}

.Checkbox-input {
  display: none;
}
