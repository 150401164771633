.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--fullvh);
}

.Login-form {
  width: 235px;
}

.Login-group {
  margin-bottom: 24px;
}

.Login-group p {
  transition: color 0.5s;
}

.Login-group input {
  transition: border-color 0.5s;
}

.Login-group :first-child {
  margin-bottom: 8px;
}

.Login-group-error p {
  color: var(--red);
}

.Login-group-error input {
  border-color: var(--red);
}

.Login-button-group {
  margin-top: 51px;
  display: flex;
  justify-content: center;
}

.Login-button {
  width: 120px;
}
