.Language {
  height: 100vh;
  background-color: var(--black-menu);
}

.Language-header {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.Language-row {
  border-bottom: 1px solid var(--grey-border-1-20);
  display: flex;
  align-items: center;
  height: 48px;
}

.Language-row-text {
  margin-left: 20px;
  flex: 1;
}

.Language-row-icon {
  flex: 0 0 48px;
  display: flex;
  justify-content: center;
}
