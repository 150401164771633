.AddPicturesToPlanning-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--fullvh);
  background-color: white;

  display: flex;
  flex-flow: column;
}

.AddPicturesToPlanning-confirm-image {
  flex: 1;
}

.AddPicturesToPlanning-confirm-image img {
  max-width: 100%;
  max-height: 100%;
}

.AddPicturesToPlanning-confirm-buttons {
  display: flex;
}

.AddPicturesToPlanning-confirm-buttons > :first-child {
  margin-right: 1px;
}
