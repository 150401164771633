.Planning-header {
  position: relative;
  width: 100%;
}

.Planning-header-icon {
  height: 64px;
  width: 64px;
  position: absolute;
}

.Planning-header-icon button {
  height: 64px;
}

.Planning-header-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Planning-scroll {
  height: calc(var(--fullvh) - 64px);
  overflow-y: scroll;
}

.Planning-scroll:first-child .Planning-nav-button-left {
  display: none;
}

.Planning-scroll:last-child .Planning-nav-button-right {
  display: none;
}

.Planning-nav {
  position: relative;
  display: flex;
  height: 56px;
}

.Planning-nav-date {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Planning-nav-date :first-child {
  margin-right: 12px;
}

.Planning-nav-button {
  position: absolute;
  right: 0;
  width: 56px;
}

.Planning-nav-button-left {
  left: 0;
  right: unset;
}

.Planning-rows {
  padding: 0 8px 8px;
  min-height: calc(var(--fullvh) - 64px - 56px - 8px);
}

.Planning-work-item {
  margin-bottom: 8px;
}

.Planning-work-item:last-child {
  margin-bottom: 0;
}

.Planning-work-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.Planning-work-title :first-child {
  margin-right: 11px;
}

.Planning-work-cardrow {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.Planning-work-info-left {
  flex: 1;
  padding: 16px 0;
}

.Planning-work-info-right {
  flex: 2;
}

.Planning-work-workers {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.Planning .Card {
  cursor: pointer;
}

.Planning-swipe {
  height: 100%;
}
