.Camera {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  background-color: white;
}

.Camera-canvas {
  display: none;
}

.Camera-container {
  height: calc(100vh - 56px - 56px);
  overflow-y: scroll;
  position: relative;
}

.Camera-video {
  width: 100%;
}

.Camera-header {
  display: flex;
  align-items: center;
}

.Camera-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
}
