.AJP-header {
  display: flex;
  align-items: center;
  flex: 1;
}

.AJP-header :first-child {
  flex: 1;
}

.AJP-header button {
  width: 100px;
}

.AJP-worker-row {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid var(--grey-border-2);
}

.AJP-worker-row p {
  flex: 1;
}

.AJP-worker-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJP-workers {
  margin-bottom: 8px;
}

.AJP-radio-container {
  border: 1px solid var(--grey-border-2);
  border-left: none;
  border-right: none;
  padding: 8px;
  padding-bottom: 16px;
}

.AJP-radio-row {
  display: flex;
  align-items: center;
  height: 32px;
}

.AJP-radio-row p {
  width: 120px;
}

.AJP-radio-input {
  width: 84px;
}

.AJP-radio {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.AJP-radio-circle {
  width: 14px;
  height: 14px;
  border: 1px solid var(--purple);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AJP-radio-ball {
  width: 12px;
  height: 12px;
  background-color: var(--purple);
  border-radius: 50%;
}

.AJP-save {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 3px 9px black;
  display: flex;
}

.AJP-button {
  display: flex;
  justify-content: center;
}

.AJP-button :first-child {
  margin-right: 14px;
}

.AJP-radio-container-blue .AJP-radio-circle {
  border-color: var(--blue);
}

.AJP-radio-container-blue .AJP-radio-ball {
  background-color: var(--blue);
}
