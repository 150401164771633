body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --fullvh: calc(var(--vh, 1vh) * 100);
  --orange: #F7611C;
  --orange-light: #FFF0EA;
  --red: #DD0021;
  --green: #00A713;
  --purple-medium: #CC5AFF;
  --purple: #B100FF;
  --purple-light: #F7E3FF;
  --purple-extra-light: #FDF7FF;
  --purple-alt: #D066FF;
  --grey-border-1: #C7C7C7;
  --grey-border-2: #E2E2E2;
  --grey-background-1: #F7F7F7;
  --grey-background-2: #F0F0F0;
  --grey-dark: #404040;
  --grey-dark-50: #919191;
  --grey-text: #4A5961;
  --black-menu: #1A1A1A;
  --grey-border-1-20: #3d3d3d;
  --blue: #0076FF;
  --blue-light: #D0E6FF;
  --blue-extra-light: #F1F7FF;
  --blue-medium: #4098FF;
}
