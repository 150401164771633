.EndJob-header {
  display: flex;
  flex: 1;
  align-items: center;
}

.EndJob-header-title {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.EndJob-title > :first-child {
  margin-bottom: 3px;
}

.EndJob-header-icon {
  height: 100%;
  flex: 0 0 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EndJob-head {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple);
  position: relative;
}

.EndJob-head-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 16px;
  position: absolute;
}

.EndJob-table-head {
  height: 48px;
  display: flex;
}

.EndJob-table-head-title {
  height: 100%;
  border-right: 1px solid var(--grey-border-2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.EndJob-table-head-title:last-child {
  border-right: none;
}

.EndJob-table-body {}

.EndJob-table-body-row {
  display: flex;
  flex-flow: column;
}

.EndJob-table-row-top {
  display: flex;
}

.EndJob-table-body-amount {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--purple-light);
}

.EndJob-table-body-rest {
  flex: 3;
  border-top: 1px solid transparent;
}

.EndJob-table-body-rest-container {
  display: flex;
  height: 48px;
  background-color: var(--purple-light);
}

.EndJob-table-body-rest-cell {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.EndJob-table-body-extra {
  background-color: var(--purple-extra-light);
}

.EndJob-table-body-extra-row {
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.EndJob-scroll {
  overflow-y: scroll;
  height: calc(var(--fullvh) - 72px - 56px);
}

.EndJob-extra-head {
  background-color: var(--grey-dark);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.EndJob-extra-icon {
  position: absolute;
  left: 32px;
}

.EndJob-extra {
  margin-bottom: 13px;
}

.EndJob-extra-row {
  display: flex;
}

.EndJob-extra-row-name {
  flex: 3;
  background-color: var(--grey-background-2);
  display: flex;
  flex-flow: column;
  justify-content: center;
 }

.EndJob-extra-row-name > * {
  display: flex;
  align-items: center;
}

.EndJob-extra-row-name > button {
  height: unset;
}

.EndJob-extra-row-content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.EndJob-extra-row-content-2 {
  flex: 1;
}

.EndJob-extra-row-content-top {
  width: 100%;
  height: 48px;
  display: flex;
}

.EndJob-extra-row-content-amount {
  flex: 1 1;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.EndJob-extra-row-content-name {
  flex: 3 1;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.EndJob-extra-row-content-description {
  font-size: 10px;
  font-weight: 400;
  background-color: var(--grey-background-1);
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.EndJob-extra-row-delete {
  width: 48px;
  background-color: var(--grey-background-2);
  padding-left: 8px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.EndJob-sign {
  border-top: 1px solid var(--grey-border-2);
  border-bottom: 1px solid var(--grey-border-2);
  padding-bottom: 16px;
}

.EndJob-sign-input {
  padding: 8px 16px;
}

.EndJob-sign-icons {
  display: flex;
}

.EndJob-sign-icons > :first-child {
  position: relative;
  top: 7px;
}

.EndJob-sign-canvas-container {
  margin: 0 16px;
  height: 160px;
  position: relative;
}

.EndJob-canvas {
  width: calc(100% - 2px);
  height: 158px;
  border-radius: 2px;
  border: 1px solid var(--grey-border-1);
  background-color: var(--grey-background-1);
}

.EndJob-sign-canvas-clear-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
}

.EndJob-file-input {
  display: none;
}

.EndJob-pictures-gallery {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
}

.EndJob-picture-container:nth-child(odd) {
  margin-right: 8px;
}

.EndJob-picture-container {
  width: calc(50vw - 16px - 4px);
  height: calc(50vw - 16px - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.EndJob-picture-container img {
  max-width: 100%;
  max-height: 100%;
}

.EndJob-picture-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background-color: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--grey-text);
}

.EndJob-picture-delete button {
  height: 48px;
}

.EndJob-pictures {
  margin: 16px;
}

.EndJob-pictures-buttons {
  display: flex;
}

.EndJob-pictures-buttons > :first-child {
  margin-right: 8px
}

.EndJob-pictures-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EndJob-pictures-button > :first-child {
  margin-right: 16px;
}

.FinishedJob-pictures-buttons > :first-child {
  margin-right: 1px;
}

.EndJob-saw .EndJob-head {
  background-color: var(--blue);
}

.EndJob-saw .EndJob-table-body-amount {
  border-top: 1px solid var(--blue-light);
}

.EndJob-saw .EndJob-table-body-rest-container {
  background-color: var(--blue-light);
}

.EndJob-saw .EndJob-table-body-extra {
  background-color: var(--blue-extra-light);
}
