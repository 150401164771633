.Dropdown {
  position: relative;
}

.Dropdown-head {
  display: flex;
  height: 48px;
  cursor: pointer;
}

.Dropdown-head:hover {
  background-image: linear-gradient(#00000010, #00000010);
}

.Dropdown-head-icon {
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown-head-title {
  flex: 1;
  display: flex;
  align-items: center;
}

.Dropdown-list {
  display: none;
  position: absolute;
  width: calc(100% - 2px);
  z-index: 1;
  background-color: white;
  border-left: 1px solid var(--grey-border-2);
  border-right: 1px solid var(--grey-border-2);
}

.Dropdown-list-open {
  display: initial;
}
