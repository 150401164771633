.UpdateMessage-root {
  overflow-y: hidden;
  position: absolute;
  bottom: 20px;
  height: 0px;
  transition: height 1s ease-in-out;
}

.UpdateMessage-slide {
  background-color: var(--grey-dark);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 0 16px;
  z-index: 25;
}

.UpdateMessage-visible {
  height: 74px;
}

.UpdateMessage-button {
  cursor: pointer;
  margin-left: 4px;
}
