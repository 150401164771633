.Comment-header {
  flex: 1;
  display: flex;
  align-items: center;
}

.Comment-header :first-child {
  flex: 1;
}

.Comment-header > button {
  width: 100px;
}

.Comment-body textarea {
  min-height: 96px;
  min-width: calc(100% - 16px);
  max-width: calc(100% - 16px);
}

.Comment-buttons {
  display: flex;
  margin-top: 16px
}

.Comment-buttons > :first-child {
  margin-right: 8px
}

.Comment-save {
  position: relative;
  box-shadow: 0px 3px 9px black;
  display: flex;
}

.Comment-save button:last-child {
  flex: 3;
}

.Comment-save button {
  flex: 2;
}

.Comment-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Comment-button > :first-child {
  margin-right: 16px;
}

.Comment-file-input {
  display: none;
}

.Comment-scroll {
  height: calc(var(--fullvh) - 56px - 56px);
  overflow-y: scroll;
}

.Comment-input {
  padding: 16px;
}

.Comment-pictures {
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
}

.Comment-picture-container:nth-child(odd) {
  margin-right: 8px;
}

.Comment-picture-container {
  width: calc(50vw - 16px - 4px);
  height: calc(50vw - 16px - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Comment-picture-container img {
  max-width: 100%;
  max-height: 100%;
}

.Comment-picture-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background-color: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--grey-text);
}

.Comment-picture-delete button {
  height: 48px;
}
